<template>
  <v-container fluid grid-list-xl py-0>
    <v-form
      ref="form"
      class="pa-4"
      :class="$style.applicationAuthorizeForm"
      lazy-validation
    >
      <v-card>
        <v-toolbar flat dark height="50">
          <b>SWEEEP提携の開始</b>
        </v-toolbar>
        <v-card-text class="justify-center">
          <div v-if="loading.page == true" class="ma-5 text-xs-center">
            <v-progress-circular indeterminate color="primary" />
          </div>
          <div v-else class="pa-4">
            <div v-if="error.get == false">
              <v-layout row wrap>
                <h2>{{ appName }} が sweeepとの連携をリクエストしています</h2>
              </v-layout>
              <v-row>
                <v-autocomplete
                  v-model="selectedUserOrganization"
                  class="ma-4"
                  :items="$store.getters.userOrganizations"
                  label="提携先組織"
                  hide-details
                  item-text="organization_name"
                  item-value="user_organization_id"
                />
              </v-row>
              <!-- <v-layout row wrap>{{ appName }} will allow the application to access your sweeep account.</v-layout> -->
              <br />
              <b>{{ appName }}は sweeepに対して以下のアクセス権限を持ちます:</b>
              <v-layout
                row
                style="background: #f4f4f4; padding: 20px; margin-top: 10px"
              >
                <ul>
                  <li>所属している組織の名前、住所、電話番号の情報</li>
                  <li v-for="scope in scopes" :key="scope.id">
                    {{ scopeDescriptions[scope.id] }}
                  </li>
                </ul>
              </v-layout>
              <v-row wrap class="mt-4">
                <v-col md="4" offset-md="8">
                  <v-btn @click="redirectOnError()"> 拒否する </v-btn>
                  <v-btn
                    class="mr-2"
                    color="primary"
                    :loading="loading.authorize"
                    @click="authorize()"
                  >
                    許可する
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <span>{{ error.message }}</span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>
<style module>
.applicationAuthorizeForm {
  max-width: 680px;
  position: absolute;
  min-width: 680px;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
<script>
import Snackbar from 'Helpers/snackbar/index'
import Axios from 'axios'

export default {
  data() {
    return {
      error: {
        get: false,
        message: '',
      },
      client_id: null,
      appName: null,
      loading: {
        authorize: false,
        page: true,
      },
      scopes: [],
      scopeDescriptions: {
        1: '請求書のアップロードとアップロード結果の読み取り',
        2: '仕訳マスタの読み取り',
        3: '請求書データの作成',
        4: '請求書データの読み取り',
      },
      selectedUserOrganization: 0,
      userOrganizations: [],
    }
  },
  created() {
    this.checkClientID()
  },
  mounted() {
    this.selectedUserOrganization = this.$store.getters.getUserOrganizationID
  },
  methods: {
    redirectOnError() {
      // https://client.example.com/cb#error=access_denied&state=xyz
      let redirectUri = this.$route.query.redirect_url + '?error=access_denied'
      if (this.$route.query.state) {
        redirectUri += `&state=${this.$route.query.state}`
      }
      window.location = redirectUri
    },
    authorize() {
      this.loading.authorize = true
      const state = this.$route.query.state
      Axios.post(
        this.$store.getters.oauth2AuthorizeUrl,
        {
          client_id: this.client_id,
          response_type: 'code',
          state,
          redirect_url: this.$route.query.redirect_url,
          user_organization_id_for_authorizing: this.selectedUserOrganization,
        },
        {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        }
      ).then((response) => {
        if (response.status == 200) {
          window.location.href = response.data.data
        } else {
          this.error.get = true
          this.error.message = response.data.error
          Snackbar.error(response.data.error)
        }
      })
    },
    checkClientID() {
      Axios.get(this.$store.getters.oauth2AuthorizeUrl, {
        headers: { Authorization: this.$store.getters.getAuthToken },
        params: {
          client_id: this.$route.query.client_id,
          organization_id: this.$store.getters.getOrganizationID,
          redirect_url: this.$route.query.redirect_url,
          response_type: 'code',
        },
      })
        .then((response) => {
          if (response.status === 200) {
            this.client_id = response.data.data.client_id
            this.scopes = response.data.data.scopes
            this.appName = response.data.data.app_name
          } else {
            this.error.get = true
            this.error.message = 'アプリケーションは未承認です。'
            Snackbar.error(response.data.error)
          }
          this.loading.page = false
        })
        .catch(() => {
          Snackbar.error(
            '認証情報が不正です。アプリケーション管理者にご連絡ください。'
          )
          this.error.message = '認証情報が不正です。'
          this.error.get = true
          this.loading.page = false
        })
    },
    getUserOrganizations() {
      let userOrganizations = this.$store.getters.userOrganizations
      if (userOrganizations.length === 0) {
        this.$store
          .dispatch('getUserOrganizations')
          .then((resUserOrganizations) => {
            return [...resUserOrganizations]
          })
          .catch(() => {
            Snackbar.error('組織を取得することができませんでした。')
          })
      } else {
        return [...userOrganizations]
      }
    },
  },
}
</script>
